import axios from 'axios';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import { ServerTable, ClientTable } from 'vue-tables-2';
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import VueQRCodeComponent from 'vue-qrcode-component';
import VueClipboard from 'vue-clipboard2';
import VueSweetalert2 from 'vue-sweetalert2';
import { VTooltip } from 'v-tooltip';
import VSelect from 'vue-select';
import App from './components/App';
import VueYoutube from 'vue-youtube';
import VueFragment from 'vue-fragment';
import store from './state';
import router from './router';
import wait from './plugins/wait';
import config from '../../load-config';
import i18n from './plugins/i18n';
import './plugins';
import './components';
import 'vue-select/dist/vue-select.css';
import '@groovepages/gd-ui-kit/styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.get('lang');
if (lang) localStorage.setItem('lang', lang);

config.then(configValues => {
  axios.defaults.baseURL = `${configValues.VUE_APP_API_URL}`;
  let appFrontendURL = configValues.VUE_APP_FRONTEND_URL;

  if (configValues.VUE_APP_FRONTEND_URL.includes('/groovepages')) {
    appFrontendURL = configValues.VUE_APP_FRONTEND_URL.replace(
      '/groovepages',
      ''
    );
  }
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;
  if (user && !user.auth_keycloak) {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      'x-auth-provider': 'auth0'
    };
  }

  const localConfig = {
    apiUrl: `${configValues.VUE_APP_API_URL}`,
    appUrl: `${configValues.VUE_APP_GROOVEVIDEO_URL}`,
    grooveappsurl: appFrontendURL,
    apiCoreUrl: `${configValues.VUE_APP_API_URL}/groovedigital`
  };
  window.config = localConfig;
  window.gdEnv = configValues;

  Vue.config.productionTip = false;

  Vue.directive('resize', {
    bind(el, { value = {} }) {
      el.addEventListener('load', () => iFrameResize(value, el));
    }
  });

  Vue.use(VueYoutube);
  Vue.use(VueFragment.Plugin);
  Vue.use(PortalVue);
  Vue.use(ClientTable);
  Vue.use(ServerTable);
  Vue.use(VueClipboard);
  Vue.use(VueSweetalert2);
  Vue.component('v-select', VSelect);
  Vue.component('qr-code', VueQRCodeComponent);
  Vue.directive('tooltip', VTooltip);

  Vue.filter(

  )

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    i18n,
    store,
    router,
    wait,
    ...App
  });
});
