<template>
  <base-layout :menuItems="menuItems" logo="GdLogo">
    <template slot="content">
      <div class="w-full h-full flex" id="groovesellCont">
        <VuePerfectScrollbar
          class="w-full h-full"
          :settings="scrollbarSettings"
        >
          <transition :name="routerTransition">
            <router-view></router-view>
          </transition>
        </VuePerfectScrollbar>
      </div>
    </template>
    <template slot="header-bar">
      <header-bar
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        :routeTitle="routeTitle"
      ></header-bar>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@groovepages/gd-ui-kit/components/BaseLayout';
import store from '@/state';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  data() {
    return {
      isExternal: true,
      showSlider: false,
      routerTransition: 'zoom-fade',
      routeTitle: this.$route.meta.pageTitle,
      isAdmin: null,
      scrollbarSettings: {
        maxScrollbarLength: 60,
      },
    };
  },

  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
  },

  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
  },
  computed: {
    menuItems() {
      return [];
    },
  },
  async created() {
    this.isAdmin = store.state.user.admin;
  },
  components: {
    BaseLayout,
    VuePerfectScrollbar,
  },
};
</script>


<style scoped>
#groovesellCont {
  height: calc(100vh - 3.25rem) !important;
}
</style>
