<template>
    <div class="flex flex-col h-screen" >
        <div class="flex flex-row w-full bg-white border-b border-solid h-13 border-grey-200">
            <div class="flex items-center flex-grow px-2">
                <a href="javascript:void(0)" @click="movetoBackPage()"><i class="fas fa-chevron-left"></i></a> <slot name="header-bar"></slot>
            </div>
            <!-- <div class="flex items-center justify-center">
                <gd-button class="w-auto px-4 mr-2 exit" @click="exitScreen()">Exit</gd-button>
            </div> -->
        </div>
        <div class="flex w-full h-full">
            <div class="flex flex-row w-full max-h-full">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import GdLogo from '@groovepages/gd-ui-kit/assets/logo-extra-small.svg';
import directives from '@groovepages/gd-ui-kit/directives';


export default {
  props: ['menuItems'],
  data() {
    return {
    };
  },
  methods: {
    movetoBackPage() {
      this.$router.go(-1);
    }
  },
  components: {
    GdLogo,
  },
  directives,
  name: 'BaseLayout',
};
</script>
<style scoped>

.fa-chevron-left{
  color: #d5d8e5;
}
</style>