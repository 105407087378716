<template>
    <div>
    </div>
</template>

<script>
import auth from '@groovepages/gd-lib/auth';


export default {
  middleware: 'auth',
  data() {
    return {
    };
  },
  metaInfo() {
    return { title: 'Upgrade'};
  },
  async created() {
    if (auth.authenticatedUser())
    {
        window.location.replace(`https://upgrade.groove.cm/upgrade/${auth.authenticatedUser().id}`);;
    }
  },
};
</script>