<template></template>
<script>
import GdLogin from '@groovepages/gd-ui-kit/components/Login';
import publisher from 'publisher';
import axios from 'axios';

export default {
  middleware: 'auth',
  layout: 'iframe',
  async created() {
    const params = new URLSearchParams(
      new URL(document.location).hash.split('?')[1]
    );
    const returnURL = params.get('returnURL');
    console.log('this is params');
    console.log(params);
    console.log(returnURL);

    if (returnURL) {
      const jwt = require('jsonwebtoken');
      const ssoToken = '17e654c1-2d38-49c0-93ff-7adfc500e90d';
      const user = JSON.parse(localStorage.getItem('user'));
      const userData = {
        email: user.email,
        name: user.name,
      };
      console.log('this is userdata');
      console.log(userData);

      const userToken = jwt.sign(userData, ssoToken, { algorithm: 'HS256' });

      console.log(userToken);

      window.location.href = `${returnURL}?token=${userToken}`;
    }
  },
  data() {
    return {};
  },
  components: {
    GdLogin,
  },
};
</script>