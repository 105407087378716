<template>
    <base-layout :menuItems="menuItems" class="h-auto-important">
        <template slot="content">
            <sidebar-menu external="isExternal" showSlider="showSlider"></sidebar-menu>
            <div class="w-full min-h-full p-5 pt-3">
                <transition :name="routerTransition">
                    <div class="router-header flex flex-wrap items-center" v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                        <h5 class="mb-1">{{ routeTitle }}</h5>
                    </div>
                </transition>
                <router-view v-if="isAdmin"></router-view>
                <div v-else>
                    Currently closed. Doors Will Open On December 8th.
                </div>
            </div>
        </template>
    </base-layout>
</template>


<script>
import store from '@/store';

export default
{
  data() {
    return {
      isExternal: true,
      showSlider: false,
      routerTransition: 'zoom-fade',
      routeTitle: this.$route.meta.pageTitle,
      isAdmin: null,
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
  },
  methods: {

    changeRouteTitle(title) {
      this.routeTitle = title;
    },

    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    },

    myAccount() {
      console.log('clicked on my account');
    },
  },
  computed: {
    menuItems() {
      return [
        {
          text: 'Logout',
          callback: this.logout,
        },
      ];
    },
  },
  async created() {
    this.isAdmin = store.getters['auth/admin'];
  },
};
</script>

<style scoped>
    .h-auto-important
    {
        height: auto !important;
    }
</style>
