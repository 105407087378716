import Vue from 'vue';
import Vuex, { mapState } from 'vuex';
import core from '@groovepages/gd-lib/core-store';
import records from '@groovepages/gd-lib/records-store';
import domains from '@groovepages/gd-lib/domain-store';
import subdomains from '@groovepages/gd-lib/subdomains-store';

Vue.use(Vuex);

Vue.mixin({
  computed: {
    ...mapState(['lang'])
  }
});

export default new Vuex.Store({
  ...core,
  modules: {
    core,
    records,
    domains,
    subdomains,
  },
  state: {},
  getters: {
    user: state => state.user,
    isLiteUser: state => {
      return (
          ( state.user.roles && state.user.roles.indexOf( 'groovepages_user' ) === -1 ) || ( state.user.plan && Array.isArray( state.user.plan ) && state.user.plan.length > 0 && state.user.plan[ 0 ].toLowerCase() === 'startup')
      );
    },
    metaData: state => {
      return state.user.metadata;
    },
  }
});
