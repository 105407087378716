<template>
  <div class="my-32 flex flex-row justify-center">
    <div
      v-if="showErrorMessage"
      class="flex flex-col justify-center items-center"
    >
      <div>
        {{ $t('frontend_dashboard_sso_unable_login') }}.
      </div>
      <div>{{ $t('frontend_dashboard_sso_redirect') }}....</div>
    </div>
    <div v-else class="flex flex-row justify-center">
      {{ $t('frontend_dashboard_sso_success_login') }}....
    </div>
  </div>
</template>

<script>
import publisher from 'publisher';
import axios from 'axios';
import auth from '@groovepages/gd-lib/auth';

export default {
  layout: 'basic',
  async created() {
    const params = new URLSearchParams(
      new URL(document.location).hash.split('?')[1]
    );
    const token = params.get('token');
    auth
      .ssoLogin(token)
      .then((user) => {
        console.log('this is user');
        this.loading = false;
        this.setUserData(user);
      })
      .catch((err) => {
        console.log(err);
        this.showErrorMessage = true;
        window.setTimeout(() => {
          // Move to a new location or you can do something else
          window.location.href = 'https://app.groove.cm/#/login';
        }, 3000);
      });
  },
  data() {
    return {
      showErrorMessage: false,
    };
  },
  components: {},
  methods: {
    async setUserData(user) {
      this.$store.commit('login', user);
      if (!user.auth_keycloak) {
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          'x-auth-provider': 'auth0',
        };
      }
      let redirect = localStorage.getItem('redirect');

      const rootDomainRedirects = [
        'https://app.groovefunnels.com/#/',
        'https://app.groovefunnels.com/#',
        'https://app.groovefunnels.com',
        'https://app.groovefunnels.com/',
        'https://app.groove.cm/#/',
        'https://app.groove.cm/#',
        'https://app.groove.cm',
        'https://app.groove.cm/',
      ];

      if (
        !redirect ||
        redirect.includes('/login') ||
        rootDomainRedirects.indexOf(redirect) > -1
      )
        redirect = '/';

      let url = false;

      try {
        url = new URL(redirect);
      } catch (_) {}

      if (url && (url.protocol === 'http:' || url.protocol === 'https:')) {
        window.location.replace(redirect);
      }

      if (redirect.indexOf('builder') !== -1) {
        window.location.replace(redirect);
      } else if (this.$router) this.$router.push({ path: redirect });
      else publisher.publish('logged-in');
    },
  },
};
</script>