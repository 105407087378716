<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex flex-col ">
      <div class="border-solid border-grey-200 border-b p-4 relative">
        <p class="text-base text-gray-500 flex flex-row items-center font-bold">
          {{ title }}
        </p>
      </div>
      <div class="w-full h-full justify-center">
        <div
          class="
            w-full
            flex flex-row
            p-5
            justify-between
            items-center
            borderBottom
          "
          v-if="filterVisible"
        >
          <p
            class="text-base text-color font-bold"
            v-if="title === 'GrooveMail'"
          >
            {{ $t('frontend_gd_dashboard_recent_broadcast') }}
          </p>
          <p
            class="text-base text-color font-bold pr-2 md:pr-0"
            v-if="title === 'GrooveAffiliate'"
          >
            {{ $t('frontend_gd_dashboard_recent_promos') }}
          </p>
          <p
            class="text-base text-color font-bold"
            v-if="title === 'GroovePages'"
          >
            {{ $t('frontend_gd_dashboard_recent_sites') }}
          </p>
          <p
            class="text-base text-color font-bold"
            v-if="title === 'GrooveVideo'"
          >
            {{ $t('frontend_gd_dashboard_myvideos') }}
          </p>
          <div class="flex rounded timePeriod">
            <span
              class="
                border-solid border-grey-200 border
                first
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 0 === activeItem }"
              @click="days(title, 0, 0)"
              >{{ $t('frontend_gd_dashboard_today') }}</span
            >
            <span
              class="
                border-solid border-grey-200 border
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 1 === activeItem }"
              @click="days(title, 1, 1)"
              >{{ $t('frontend_gd_dashboard_yesterday') }}</span
            >
            <span
              class="
                border-solid border-grey-200 border
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 2 === activeItem }"
              @click="days(title, 7, 2)"
              >{{ $t('frontend_gd_dashboard_7days') }}</span
            >
            <span
              class="
                border-solid border-grey-200 border
                second
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 3 === activeItem }"
              @click="days(title, 14, 3)"
              >{{ $t('frontend_gd_dashboard_14days') }}</span
            >
            <span
              class="
                border-solid border-grey-200 border
                third
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 4 === activeItem }"
              @click="days(title, 30, 4)"
              >{{ $t('frontend_gd_dashboard_30days') }}</span
            >
          </div>
        </div>

        <div class="w-full h-full" v-if="dataLoaded">
          <div class="w-full h-full flex flex-row">
            <div
              class="w-full h-80 justify-center items-center"
              v-if="hasUsed === 0"
            >
              <div
                class="w-full flex flex-col justify-center items-center h-full"
              >
                <p class="text-xl font-bold mb-3">
                  {{ $t('frontend_gd_dashboard_get_started_with') }} {{ title }}
                </p>
                <p class="text-sm w-1/2 text-center mb-4">
                  {{ $t('frontend_gd_dashboard_get_no_data_to') }} {{ title }}
                  {{ $t('frontend_gd_dashboard_get_today') }}.
                </p>
                <gd-button
                  primary
                  class="h-10 w-32"
                  @click="linkRedirect(title)"
                  >{{ $t('frontend_gd_dashboard_get_started') }}</gd-button
                >
              </div>
            </div>
            <div
              class="w-full h-80 justify-center items-center"
              v-else-if="hasData === 0"
            >
              <div
                class="w-full flex flex-col justify-center items-center h-full"
              >
                <img
                  src="../../assets/images/icons/dashboard/emptyinsights.png"
                />
                <p class="text-sm w-1/2 text-center mb-4 mt-4">
                  {{ $t('frontend_gd_dashboard_no_data_toshow') }}
                </p>
              </div>
            </div>
            <div v-else class="w-full h-full">
              <VuePerfectScrollbar
                class="
                  w-full
                  h-full
                  tableHeight
                  tableScrollbar
                  overflow-y-hidden
                "
                :settings="scrollbarSettings"
              >
                <v-client-table
                  :columns="columns"
                  :data="tableData"
                  :options="options"
                  class="w-full vueTable"
                >
                  <!-- groovePromo -->
                  <p
                    slot="promo_name"
                    slot-scope="promoName"
                    class="flex flex-row items-center"
                  >
                    <a :href="'/groovesell/promos/' + promoName.row.id">{{
                      promoName.row.name
                    }}</a>
                  </p>
                  <p
                    slot="optins"
                    slot-scope="promoOptins"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveAffiliate'"
                  >
                    {{
                      promoOptins.row.optins
                        ? numberSystem(promoOptins.row.optins, 0)
                        : 0
                    }}
                  </p>
                  <p
                    slot="uniques"
                    slot-scope="promouniques"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveAffiliate'"
                  >
                    {{
                      promouniques.row.uniques
                        ? numberSystem(promouniques.row.uniques, 0)
                        : 0
                    }}
                  </p>
                  <p
                    slot="commissions"
                    slot-scope="promoComm"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveAffiliate'"
                  >
                    ${{
                      promoComm.row.commissions
                        ? numberSystem(promoComm.row.commissions, 2)
                        : 0.0
                    }}
                  </p>
                  <!-- groovevideo -->
                  <p
                    slot="video_name"
                    slot-scope="props"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveVideo'"
                  >
                    <img
                      :src="
                        props.row.settings.splashImg || props.row.thumbnail_url
                      "
                      v-if="
                        props.row.settings.splashImg || props.row.thumbnail_url
                      "
                      :alt="props.row.name"
                      class="w-20 h-12 mr-2 borderRadius"
                    />
                    <a
                      :href="
                        '/groovevideo/video/' + props.row.id + '/addnewvideo'
                      "
                      >{{ props.row.name }}</a
                    >
                  </p>
                  <p
                    slot="plays"
                    slot-scope="vidPlay"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveVideo'"
                  >
                    {{
                      vidPlay.row.plays ? numberSystem(vidPlay.row.plays, 0) : 0
                    }}
                  </p>
                  <p
                    slot="completion_rate"
                    slot-scope="vidRate"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveVideo'"
                  >
                    {{
                      vidRate.row.completion_rate
                        ? numberSystem(vidRate.row.completion_rate, 0)
                        : 0
                    }}%
                  </p>
                  <!-- groovemail -->
                  <p
                    slot="broadcast_name"
                    slot-scope="props"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveMail'"
                  >
                    <a :href="'/groovemail/campaign/' + props.row.id">{{
                      props.row.name
                    }}</a>
                  </p>
                  <p
                    slot="recipients"
                    slot-scope="mailRecp"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveMail'"
                  >
                    {{
                      mailRecp.row.recipients
                        ? numberSystem(mailRecp.row.recipients, 0)
                        : 0
                    }}
                  </p>
                  <p
                    slot="opens"
                    slot-scope="mailopens"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveMail'"
                  >
                    {{
                      mailopens.row.opens
                        ? numberSystem(mailopens.row.opens, 0)
                        : 0
                    }}
                  </p>
                  <p
                    slot="clicks"
                    slot-scope="mailclick"
                    class="flex flex-row items-center"
                    v-if="title === 'GrooveMail'"
                  >
                    {{
                      mailclick.row.clicks
                        ? numberSystem(mailclick.row.clicks, 0)
                        : 0
                    }}
                  </p>

                  <p
                    slot="site_name"
                    slot-scope="props"
                    class="flex flex-row items-center"
                    v-if="title === 'GroovePages'"
                  >
                    <a :href="'/groovepages/builder/' + props.row.id">{{
                      props.row.name
                    }}</a>
                  </p>
                </v-client-table>
              </VuePerfectScrollbar>
            </div>
          </div>
        </div>
        <div class="w-full h-full" v-else>
          <tableContentLoader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import themeConfig from '@/theme/themeConfig.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import tableContentLoader from './tablecontentloader';

export default {
  middleware: 'auth',
  components: {
    Loading,
    VuePerfectScrollbar,
    tableContentLoader,
  },
  props: {
    title: {
      required: true,
    },
    tableData: {
      required: true,
    },
    tableCol: {
      required: true,
    },
    filterVisible: {
      default: true,
    },
    activeItem: {
      type: Number,
      default: 0,
    },
    errResponse: {
      default: true,
    },
    hasUsed: {
      default: 0,
    },
    hasData: {
      default: 0,
    },
    dataLoaded: {
      default: 0,
    },
    tableHeadings: {
      default: 0,
    },
  },
  data() {
    return {
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
      columns: this.tableCol,
      rowData: [],
      scrollbarSettings: {
        maxScrollbarLength: 60,
      },
      options: {
        loading: {
          type: Boolean,
          default: false,
        },
        texts: {
          noResults: this.$t('frontend_none_created'),
        },
        headings: this.tableHeadings,
        /* sortable: false,
        filterable: false, */
        pagination: {
          show: false,
        },
        perPage: 25,
      },
    };
  },
  async created() {
    this.rowData = this.tableData;
  },
  async mounted() {},
  methods: {
    days(name, number, activeNumber) {
      if (name === 'GrooveVideo') {
        this.$emit('grooveVideo', number);
      } else if (name === 'GrooveMail') {
        this.$emit('grooveMail', number);
      } else if (name === 'GrooveAffiliate') {
        this.$emit('groovePromo', number);
      } else if (name === 'GrooveMember') {
        this.$emit('grooveMember', number);
      } else if (name === 'GroovePages') {
        this.$emit('groovePage', number);
      }
    },

    linkRedirect(linkName) {
      console.log(linkName);
      if (linkName === 'GroovePages') {
        this.$emit(
          'redirectUrl',
          this.$t('frontend_gd_dashboard_manage_sites')
        );
      } else if (linkName === 'GrooveAffiliate') {
        this.$emit('redirectUrl', 'GrooveAffiliate');
      } else if (linkName === 'GrooveVideo') {
        this.$emit(
          'redirectUrl',
          this.$t('frontend_gd_dashboard_manage_videos')
        );
      } else if (linkName === 'GrooveMail') {
        this.$emit(
          'redirectUrl',
          this.$t('frontend_gd_dashboard_email_marketing')
        );
      }
    },

    numberSystem(num, decimals) {
      if (decimals === 0) {
        return parseInt(num).toLocaleString();
      }

      return parseInt(num)
        .toFixed(decimals)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  },
};
</script>

<style type="scss">
.columnWidth {
  width: 49%;
}
.rightDots {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, 0);
}
.text-color {
  color: #191d3a;
}
.vueTable .VueTables__table {
  width: 100%;
}

.vueTable .VueTables__table tr th {
  font-size: 14px;
  color: #989ab2;
  border-bottom: solid 1px #cccedc;
  text-transform: capitalize;
  font-weight: 400;
  text-align: left;
  padding: 10px 5px;
}
.vueTable .VueTables__table tr th:active,
.vueTable .VueTables__table tr th:focus,
.vueTable .VueTables__table tr td:active,
.vueTable .VueTables__table tr td:focus {
  outline: none;
}
.vueTable .VueTables__table tr td:first-child,
.vueTable .VueTables__table tr th:first-child {
  padding-left: 20px;
}
.vueTable .VueTables__table tr td:last-child,
.vueTable .VueTables__table tr th:last-child {
  padding-right: 20px;
}
.vueTable .VueTables__table tr td {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #191d3a;
  padding: 20px 5px;
  border-bottom: solid 1px #cccedc;
}
.vueTable .VueTables__table tr:last-child td {
  border-bottom: 0;
}
.vueTable .VueTables__table tr td:first-child {
  font-size: 14px;
  font-weight: 600;
  color: #fd6481;
  text-align: left;
}
.vueTable .VuePagination {
  display: none;
}

.timePeriod .first {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.timePeriod .third {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
/* .vueTable .pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vueTable .pagination li {
  margin: 0 5px;
}
.vueTable .pagination li a {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #fd6481;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
}
.vueTable .VuePagination {
  margin-bottom: 20px;
} */
.vueTable .VueTables__search,
.vueTable .VueTables__limit {
  display: none;
}
.active {
  color: #fd6481;
  border-color: #fd6481;
}

.tableScrollbar .ps__thumb-y {
  background-color: #fd6481 !important;
}
.tableHeight {
  height: 390px;
}
.errBox {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), #ffffff);
  height: calc(100vh - 343px);
  bottom: 0;
  left: 0;
}
</style>
