const common = {
	VUE_APP_FRONTEND_URL: 'http://localhost:8080',
	VUE_APP_IMAGES_URL: 'https://images.groovetech.io',

	SIGNUP_CHECKOUT_WIDGET_ID: '0dd3e93b678c2dd96d1d8c45b56e09d7',
	SIGNUP_CHECKOUT_WIDGET_ID_ASIA: '0dd3e93b678c2dd96d1d8c45b56e09d7',
	SIGNUP_CHECKOUT_WIDGET_ID_BRAZIL: '0dd3e93b678c2dd96d1d8c45b56e09d7',
	UPGRADE_URL: 'https://tracking.groovesell.com/t/727e1806d803d6204296d7299c8ebbcf',
	UPGRADE_URL_ASIA: 'https://grooveasia.cm/upgrade',
	UPGRADE_URL_BRAZIL: 'https://tracking.groovesell.com/t/727e1806d803d6204296d7299c8ebbcf',
	PARTNER_PROGRAM_URL: 'https://hrc.groovepages.com/dashboard/affiliate-promotions',
	PARTNER_PROGRAM_URL_ASIA: 'https://grooveasia.cm/jv',
	PARTNER_PROGRAM_URL_BRAZIL: 'https://hrc.groovepages.com/dashboard/affiliate-promotions',
	GROOVESELL_WHITELABEL_APP_NAME: 'groovesell',
	GROOVEPAGES_WHITELABEL_APP_NAME: 'groovepages',
	GROOVEDIGITAL_WHITELABEL_APP_NAME: 'groovedigital',
	GROOVEVIDEO_WHITELABEL_APP_NAME: 'groovevideo',
}

const test = {
	VUE_APP_API_URL: 'https://test-v1.gdapis.com/api',
	VUE_APP_STORAGE_URL: 'https://assets-preprod.grooveapps.com',
	VUE_APP_FRONTEND_NETWORK: 'https://preprod.grooveapps.com/groovepages/',

	VUE_APP_GROOVEWEBINAR_URL: 'https://preprod.groovewebinar.com/',
	VUE_APP_GROOVESELL_URL: 'https://groovesell-preprod.com/',
	VUE_APP_SELFBILLING_URL: 'https://billing.groovesell.com',
	VUE_APP_GROOVEVIDEO_URL: 'https://groovevideo.com/',
	VUE_APP_GROOVEADMIN_URL: 'https://grooveadmin.net/',
	VUE_APP_GROOVEMEMBER_URL: 'https://groovemember.net/',
	VUE_APP_GROOVEBLOG_URL: 'https://grooveblog.net/',
	VUE_APP_GROOVEPAGES_URL: 'https://groovepages-test.com'
}

const local = {
	VUE_APP_API_URL: 'http://localhost:8000/api',
	VUE_APP_STORAGE_URL: 'https://assets-test.grooveapps.com',
	VUE_APP_FRONTEND_NETWORK: 'https://test.grooveapps.com/groovepages/',
	VUE_APP_GROOVEWEBINAR_URL: 'https://preprod.groovewebinar.com/',
	VUE_APP_GROOVESELL_URL: 'https://groovesell-preprod.com/',
	VUE_APP_SELFBILLING_URL: 'https://billing.groovesell.com',
	VUE_APP_GROOVEVIDEO_URL: 'https://groovevideo.com/',
	VUE_APP_GROOVEADMIN_URL: 'https://grooveadmin.net/',
	VUE_APP_GROOVEMEMBER_URL: 'https://groovemember.net/',
	VUE_APP_GROOVEBLOG_URL: 'https://grooveblog.net/',
}

const target = local; //You change the desired environment.

export default {...target,...common};