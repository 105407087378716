<template>
  <div class="login-page">
    <gd-login :callback="callback" class="bg-dark"></gd-login>
  </div>
</template>
<script>
import GdLogin from '@groovepages/gd-ui-kit/components/Login';
import publisher from 'publisher';
import axios from 'axios';
import moment from 'moment'

export default {
  async created() {
    const page_lang = localStorage.getItem('lang');
    if (!page_lang) {
      this.$root.$i18n.locale = 'en';
      const response = await fetch('https://cloudflare.com/cdn-cgi/trace');
      const body = await response.text();
      const country = body
        .split('\n')
        .find((string) => string.includes('loc='))
        .split('=')[1];
      if (country === 'BR') {
        localStorage.setItem('lang', 'pt');
        this.$root.$i18n.locale = 'pt';
      }
    } else {
      this.$root.$i18n.locale = localStorage.getItem('lang');
    }
  },
  data() {
    return {
      callback: (user) => {
        this.$store.commit('login', user);

        if (!user.auth_keycloak) {
          axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            'x-auth-provider': 'auth0',
          };
        }
        let redirect = localStorage.getItem('redirect');

        if (typeof Tooltip !== 'undefined') {
          console.log('Setting value in tooltip');
          let userLevel = 0;

          if (user.roles.indexOf('groove_future_apps') >= 0) {
            userLevel = 1;
          } else if (user.roles.indexOf('groovepages_user') >= 0) {
            userLevel = 2;
          }

          Tooltip.API.updateUserData({
            userId: user.id,
            userLevel,
          });
        } else {
          console.log('Unable to set value in tooltip');
        }

        const rootDomainRedirects = [
          'https://app.groovefunnels.com/#/',
          'https://app.groovefunnels.com/#',
          'https://app.groovefunnels.com',
          'https://app.groovefunnels.com/',
          'https://app.groove.cm/#/',
          'https://app.groove.cm/#',
          'https://app.groove.cm',
          'https://app.groove.cm/',
        ];

        if (
          !redirect ||
          redirect.includes('/login') ||
          rootDomainRedirects.indexOf(redirect) > -1
        )
          redirect = '/';

        let url = false;

        try {
          url = new URL(redirect);
        } catch (_) {}

        if (url && (url.protocol === 'http:' || url.protocol === 'https:')) {
          window.location.replace(redirect);
        }

        if (redirect.indexOf('builder') !== -1)
        {
          window.location.replace(redirect);
        }
        else if (this.$router) this.$router.push({ path: redirect });
        else publisher.publish('logged-in');
      },
    };
  },
  components: {
    GdLogin,
  },
};
</script>
<style scoped>
.login-page {
  height: calc(100vh - 3.25rem);
}
</style>

