<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPageLoader"
      :color="loaderColor"
    ></loading>
    <h4 class="text-lg font-bold mb-8">{{ $t('frontend_more') }}</h4>
  </div>
</template>

<script>
import axios from 'axios';
import InfoPanel from '@groovepages/gd-ui-kit/components/InfoPanel';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import themeConfig from '@/theme/themeConfig.js';

export default {
  middleware: 'auth',
  components: {
    Loading,
  },
  data() {
    return {
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
    };
  },
  metaInfo() {
    return { title: 'Dashboard App'};
  },
  computed: {
    
  },
  async created() {
    this.showLoader();

    this.hideLoader();
  },
  async mounted() {
    
  },
  methods: {
    showLoader() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },

    hideLoader() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

  }
};
</script>

<style>
.appBox .imgBox{
  width: 112px;
  height: 112px;
  border-radius: 18.7px;
  box-shadow: 0 4px 9px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  border: solid 1.2px #cccedc;
  background-color: #ffffff;
}
.appBox:last-child{
  margin-right: 0;
}
</style>
