<template>
  <div class="layout--full-page">
    <child />
  </div>
</template>

<script>
export default {
  name: 'BasicLayout',
};
</script>
