<template>
    <div class="flex w-full flex-col justify-center">
        <div class="relative border w-full mr-6 bg-white rounded-lg p-6 border-solid border-grey-300" :class="{'hover:cursor-pointer' : this.optionEnabled, 'hover:cursor-not-allowed text-grey-600' : !this.optionEnabled , 'bg-pink-extra-light shadow-outline-blue' : this.activeOption === this.optionValue}" tabindex="0" @click="updateActiveOption" @keydown.space="updateActiveOption">
            <div v-if="optionEnabled" class="flex justify-end items-center mb-3 absolute top-0 right-0 mt-2 mr-2 text-green-dark">
                <i class="fas fa-check-circle"></i>
            </div>

            <div class="flex justify-center items-center mb-3">
                <i :class="optionIcon"></i>
            </div>

            <div class="mb-1 font-semibold text-center">
                <p class="text-md">{{ this.optionName }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default
{
  name: 'font-awesome-radio',
  model: {
    prop: 'activeOption',
    event: 'onUpdateOption',
  },
  props: ['optionName', 'optionIcon', 'activeOption', 'optionEnabled', 'optionValue'],
  methods: {
    updateActiveOption()
    {
        if(this.optionEnabled)
        {
            this.$emit('onUpdateOption', this.optionValue);
        }
    },
  },
};
</script>

<style>
    .checkoutRadioIcon
    {
        width: 80px;
        height: 80px;
    }
</style>
