<template>
  <div class="layout--full-page">
    <VuePerfectScrollbar class="w-full h-full" :settings="scrollbarSettings">
      <transition :name="routerTransition">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import store from '@/state';

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      routerTransition: 'zoom-fade',
      routeTitle: this.$route.meta.pageTitle,
      scrollbarSettings: {
        maxScrollbarLength: 60,
      },
    };
  },
  computed: {},

  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
  },
  async created() {},

  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
  },
};
</script>
