import Cookies from 'js-cookie';
import * as types from '../mutation-types';

// state
export const state = {
  user: Cookies.get('user'),
  token: Cookies.get('token'),
  userRole: 'guest',
  scope: Cookies.get('scope'),
  admin: Cookies.get('admin'),
};

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  scope: state => state.scope,
  admin: state => state.admin,
  check: state => (state.user && state.user !== null && state.user !== ''),
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    Cookies.set('token', token, { expires: remember ? 365 : null });
  },

  [types.FETCH_USER_SUCCESS](state, { user, scope, admin }) {
    state.user = user;
    state.scope = scope;
    state.admin = admin;
    Cookies.set('user', user);
    Cookies.set('scope', scope);
    Cookies.set('admin', admin);
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove('token');
    Cookies.remove('user');
    Cookies.remove('scope');
    Cookies.remove('admin');
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;
    state.scope = '';
    state.admin = null;
    Cookies.remove('token');
    Cookies.remove('user');
    Cookies.remove('scope');
    Cookies.remove('admin');
  },

  [types.UPDATE_USER](state, { user, scope, admin }) {
    state.user = user;
    state.scope = scope;
    state.admin = admin;
    Cookies.set('user', user);
    Cookies.set('scope', scope);
    Cookies.set('admin', admin);
  },
};

// actions
export const actions = {
  saveToken({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit, rootState }, payload) {
    try
    {
      commit(types.FETCH_USER_SUCCESS, payload);
      rootState.user = payload.user;
    }
    catch (e)
    {
      commit(types.FETCH_USER_FAILURE);
    }
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async logout({ commit }) {
    commit(types.LOGOUT);
  },
};
