<template>
  <div class="flex flex-col w-full">
    <div
      class="w-full h-full relative border border-solid rounded-lg text-grey-600 border-grey-200"
      :class="{ 'border-pink-dark error-form-group': hasErrors }"
    >
      <textarea
        ref="input"
        :placeholder="placeholder"
        :value="value"
        class="w-full h-full outline-none rounded-lg px-4 resize-none"
        :class="{
          'bg-black text-white': isCodeEditor,
          'bg-white text-grey-600': !isCodeEditor
        }"
        :disabled="disabled"
        :readonly="readonly"
        @keyup="keyup"
        @keydown="keydown"
        @focus="inputFocussed"
      ></textarea>
    </div>
    <div v-if="hasErrors" class="w-full error text-pink-dark mt-1 text-sm">
      {{ firstErrorMessage }}
    </div>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'TextArea',
  mixins: [singleErrorExtractorMixin],
  props: {
    dark: {},
    value: {},
    placeholder: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    isCodeEditor: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    keyup() {
      this.$emit('input', this.$refs.input.value);
      this.$emit('keyup', this.$refs.input.value);
    },

    keydown(e) {
      this.$emit('keydown', e);
    },

    inputFocussed(e) {
      if (this.selectAll) {
        e.target.select();
      }
    }
  }
};
</script>
