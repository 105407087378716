<template>
  <div>
    <ContentLoader viewBox="0 0 1060 500" height="500" width="1060">
      <rect x="30" y="40" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="40" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="40" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="40" rx="6" ry="6" width="150" height="60"></rect>

      <rect x="30" y="120" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="120" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="120" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="120" rx="6" ry="6" width="150" height="60"></rect>

      <rect x="30" y="200" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="200" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="200" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="200" rx="6" ry="6" width="150" height="60"></rect>

      <rect x="30" y="280" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="280" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="280" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="280" rx="6" ry="6" width="150" height="60"></rect>

      <rect x="30" y="360" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="360" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="360" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="360" rx="6" ry="6" width="150" height="60"></rect>

      <rect x="30" y="440" rx="6" ry="6" width="500" height="60"></rect>
      <rect x="580" y="440" rx="6" ry="6" width="100" height="60"></rect>
      <rect x="700" y="440" rx="6" ry="6" width="150" height="60"></rect>
      <rect x="900" y="440" rx="6" ry="6" width="150" height="60"></rect>
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  middleware: 'auth',
  components: {
    ContentLoader
  },
  props: {},
  data() {
    return {};
  },
  async created() {},
  async mounted() {},
  methods: {}
};
</script>
