<template>
  <div class="w-full relative h-10 text-grey-600 border border-solid border-grey-200 rounded-lg bg-white">
    <input type="password" @keyup="keyup" ref="input" @keydown="keydown" :placeholder="placeholder" :value="value" class="w-full h-full outline-none rounded-lg px-4">
  </div>
</template>

<script>
export default {
  props: ['value', 'placeholder'],
  methods: {
    keyup() {
      this.$emit('input', this.$refs.input.value);
      this.$emit('keyup', this.$refs.input.value);
    },
    keydown(e) {
      this.$emit('keydown', e);
    },
  },
  name: 'PasswordInput',
};
</script>
