<template>
    <div class="flex flex-col justify-center border border-grey-300 border-solid p-4 mb-6" tabindex="0" @click="updateActiveOption" @keydown.space="updateActiveOption">
        <div class="w-full flex flex-row border-b border-grey-500 border-solid py-4">
            <div class="w-1/2">
                <p class="text-grey-200">
                    {{ option.description.toUpperCase() }}
                </p>
            </div>
        </div>
        <div class="w-full flex flex-row py-4" :class="{'border-b border-grey-500 border-solid' : this.activeOption === option.id}">
            <div class="w-1/2">
                <p class="text-grey-200">
                    Total
                </p>
            </div>
            <div class="w-1/2 text-right">
                <span class="text-grey-200">
                    USD {{ parseFloat(option.price/100).toFixed(2) }}
                </span>
                <span v-if="option.recurring_interval === 1" class="text-grey-200">{{ $t('frontend_weekly') }}</span>
                <span v-else-if="option.recurring_interval === 2" class="text-grey-200">
                    {{ $t('frontend_dashboard_checkout_every_weeks') }}
                </span>
                <span v-else-if="option.recurring_interval === 3" class="text-grey-200">{{ $t('frontend_monthly') }}</span>
                <span v-else-if="option.recurring_interval === 4" class="text-grey-200">
                    {{ $t('frontend_dashboard_checkout_every_months') }}
                </span>
                <span v-else-if="option.recurring_interval === 5" class="text-grey-200">{{ $t('frontend_quarterly') }}</span>
                <span v-else-if="option.recurring_interval === 6" class="text-grey-200">
                    {{ $t('frontend_dashboard_checkout_half_yearly') }}
                </span>
                <span v-else-if="option.recurring_interval === 7" class="text-grey-200">{{ $t('frontend_yearly') }}</span>
                <span v-else-if="option.recurring_interval === 8" class="text-grey-200">
                    {{ $t('frontend_dashboard_checkout_every_years') }}
                </span>
            </div>
        </div>
        <div v-if="this.activeOption === option.id" class="w-full flex flex-row py-4 font-semibold">
            <div class="w-1/2">
                <h5 class="uppercase">
                    {{ $t('frontend_dashboard_checkout_pay_today') }}
                </h5>
            </div>
            <div class="w-1/2 text-right">
                <h5 class="">
                    USD {{ parseFloat(option.price/100).toFixed(2) }}
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'checkout-page-option',

  model: {
    prop: 'activeOption',
    event: 'onUpdateOption',
  },

  props: ['option', 'activeOption'],

  methods: {
    updateActiveOption() {
      this.$emit('onUpdateOption', this.option.id);
    },
  }
};
</script>

<style>
.checkoutRadioIcon {
  width: 80px;
  height: 80px;
}
</style>
