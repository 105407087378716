var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"tabs",class:_vm.wrapperClass},[_vm._l((_vm.tabs),function(tab){return _c('button',{key:tab.value,ref:tab.value,refInFor:true,staticClass:"tabs__item",class:[
      {'tabs__item_active' : tab.value === _vm.currentTab },
      tab.value === _vm.currentTab && _vm.tabActiveClass ? _vm.tabActiveClass: '',
      _vm.tabClass,
      _vm.disableNotCompleted ? _vm.tabCompletedOrNotClass(tab.value) : '',
      _vm.errorTabs.includes(tab.value) ? 'tabHeadingWithError' : ''
    ],attrs:{"type":"button","disabled":tab.disabled || false},domProps:{"innerHTML":_vm._s(_vm.tabTitle(tab))},on:{"click":function($event){return _vm.handleClick(tab.value)}}})}),_c('div',{staticClass:"tabs__active-line",class:_vm.lineClass,style:({ width: `${_vm.activeLineWidth}px`, transform: `translateX(${_vm.activeLineOffset}px)` })})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }