import auth from '@groovepages/gd-lib/auth';
import store from '@/state';

export default async (to, from, next) => {
  if (auth.authenticatedUser()) {
    store.commit('login', auth.authenticatedUser());
    next();
  } else {
    store.commit('logout');
    localStorage.setItem('redirect', to.path);
    window.location.replace(`${window.config.grooveappsurl}/#/login`);
  }
};
