<template>
  <div ref="iframeContainer" class="h-full -mx-5 -my-3">
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPageLoader"
      :color="loaderColor"
    ></loading>
    <iframe
      frameborder="0"
      :src="iframeSrc"
      class="w-full"
      :class="{
        hidden: loaded === 0,
        block: loaded === 1,
      }"
      style="overflow-y: hidden"
      :style="{ height: iframeHeight }"
      @load="load"
    ></iframe>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Loading from 'vue-loading-overlay';
import themeConfig from '@/theme/themeConfig.js';

export default {
  middleware: 'auth',
  components: {
    VuePerfectScrollbar,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      fullPageLoader: true,
      loaderColor: themeConfig.appPrimaryColor,
      iframeHeight: '900px',
      scrollbarSettings: {
        maxScrollbarLength: 60,
      },
      iframeSrc: `${window.config.grooveappsurl}/sso?returnURL=https://app.loopedin.io/groove-feedback-portal-WKxw#/feature-requests`,
      loaded: 0,
    };
  },
  metaInfo() {
    return { title: 'Roadmap' };
  },
  async created() {},
  async mounted() {},
  methods: {
    load() {
      this.isLoading = false;
      this.iframeHeight = `${
        window.innerHeight -
        this.$refs.iframeContainer.getBoundingClientRect().top
      }px`;
      this.loaded = 1;
    },
  },
};
</script>
