import ForgotPassword from '@groovepages/gd-ui-kit/components/ForgotPassword';
import ResetPassword from '@groovepages/gd-ui-kit/components/ResetPassword';
import Notifications from '@groovepages/gd-ui-kit/components/Notifications';
import Login from '../pages/auth/login.vue';
import SSO from '../pages/auth/sso.vue';
import welcome from '../pages/members/dashboard';
import dashboardApp from '../pages/app/dashboard-app';
import Roadmap from '../pages/members/roadmap';
import Features from '../pages/members/request-features';
import Bugs from '../pages/members/report-bugs';
import more from '../pages/more/more';
import SSOHash from '@/pages/auth/sso_hash.vue';
import i18n from '../plugins/i18n.js';
import Upgrade from '../pages/upgrade/upgrade';

ForgotPassword.layout = 'basic';
ResetPassword.layout = 'basic';
Login.layout = 'basic';

i18n.locale = 'localStorage' in window ? localStorage.getItem('lang') : 'en';

export default [
  {
    path: '/',
    name: 'welcome',
    component: welcome,
    meta: {
      pageTitle: i18n.t('frontend_dashboard_route_overview'),
      rule: 'guest'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      rule: 'guest'
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      rule: 'guest'
    }
  },
  {
    path: '/resetPassword',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      rule: 'guest'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: welcome,
    meta: {
      pageTitle: i18n.t('frontend_dashboard_route_overview'),
      rule: 'guest'
    }
  },
  {
    path: '/dashboard-app',
    name: 'dashboardApp',
    component: dashboardApp,
    meta: {
      pageTitle: 'GrooveFunnels Apps',
      rule: 'guest'
    }
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: Upgrade,
    meta: {
      rule: 'guest'
    }
  },
  {
    path: '/more',
    name: 'more',
    component: more,
    meta: {
      pageTitle: i18n.t('frontend_more'),
      rule: 'guest'
    }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      pageTitle: i18n.t('frontend_notifications'),
      rule: 'guest'
    }
  },
  {
    path: '/sso',
    name: 'sso',
    component: SSO,
    meta: {
      pageTitle: 'SSO',
      rule: 'guest'
    }
  },
  {
    path: '/hash-sso',
    name: 'sso_hash',
    component: SSOHash,
    meta: {
      pageTitle: 'SSO-Hash',
      rule: 'guest'
    }
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: Roadmap,
    meta: {
      pageTitle: 'Roadmap',
      rule: 'guest'
    }
  },
  {
    path: '/request-features',
    name: 'features',
    component: Features,
    meta: {
      pageTitle: i18n.t('frontend_dashboard_route_request_features'),
      rule: 'guest'
    }
  },
  {
    path: '/report-bugs',
    name: 'bugs',
    component: Bugs,
    meta: {
      pageTitle: i18n.t('frontend_dashboard_route_report_bugs'),
      rule: 'guest'
    }
  },
];
