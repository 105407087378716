// auth.js
export const LOGOUT = 'LOGOUT';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';

// lang.js
export const SET_LOCALE = 'SET_LOCALE';

// checkout.js
export const SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP'
export const NEXT_CHECKOUT_STEP = 'NEXT_CHECKOUT_STEP'
export const RESET_CHECKOUT_STEP = 'RESET_CHECKOUT_STEP'
export const SET_CHECKOUT_TEXTS = 'SET_CHECKOUT_TEXTS'
export const SET_CHECKOUT_FORM_DATA = 'SET_CHECKOUT_FORM_DATA'
export const SET_CHECKOUT_INIT_DATA = 'SET_CHECKOUT_INIT_DATA'
export const SET_PAYMENT_RESULT_STATUS = 'SET_PAYMENT_RESULT_STATUS'
export const SET_CHECKOUT_PLAN_DETAILS = 'SET_CHECKOUT_PLAN_DETAILS'
export const SET_CHECKOUT_PRICEPOINTS = 'SET_CHECKOUT_PRICEPOINTS'
export const SET_CHECKOUT_AFFILIATE_DATA = 'SET_CHECKOUT_AFFILIATE_DATA'
export const SET_CHECKOUT_INSTALLMENT_OPTIONS = 'SET_CHECKOUT_INSTALLMENT_OPTIONS'

// theme.js
export const UPDATE_SIDEBAR_WIDTH = 'UPDATE_SIDEBAR_WIDTH';
export const UPDATE_SIDEBAR_ITEMS_MIN = 'UPDATE_SIDEBAR_ITEMS_MIN';
export const TOGGLE_REDUCE_BUTTON = 'TOGGLE_REDUCE_BUTTON';
export const TOGGLE_CONTENT_OVERLAY = 'TOGGLE_CONTENT_OVERLAY';
export const TOGGLE_IS_SIDEBAR_ACTIVE = 'TOGGLE_IS_SIDEBAR_ACTIVE';
export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_WINDOW_BREAKPOINT = 'UPDATE_WINDOW_BREAKPOINT';
export const UPDATE_PRIMARY_COLOR = 'UPDATE_PRIMARY_COLOR';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH';
export const UPDATE_STARRED_PAGE = 'UPDATE_STARRED_PAGE';
export const ARRANGE_STARRED_PAGES_LIMITED = 'ARRANGE_STARRED_PAGES_LIMITED';
export const ARRANGE_STARRED_PAGES_MORE = 'ARRANGE_STARRED_PAGES_MORE';
