import Vue from 'vue'
import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

Vue.use(Vuelidate)
Vue.use(vuelidateErrorExtractor, {

  messages: {
      required: "{attribute} field is required",
      minLength: "{attribute} has to be atleast {min} characters",
      maxLength: "{attribute} cannot be more than {max} characters",
      numeric: "{attribute} needs to be a positive integer",
      decimal: "{attribute} needs to be a valid positive number",
      between: "{attribute} needs to be between {min} and {max}",
      minValue: "{attribute} needs to be more than {min}",
      url: "{attribute} needs to be a valid URL",
      isValidCommission: "Not a valid commission rate",
      alpha: "{attribute} only accepts alphabets",
      email: "{attribute} needs to be a valid email",
      sameAsPassword: "{attribute} needs to be same as password",
      goodPassword: "Password strength is not enough",
      isUnique: "{attribute} already taken",
      alphaNum: "{attribute} accepts only alphanumeric",
      totalHundredPercent: "Sum of percentages should add upto 100"
  },
});
