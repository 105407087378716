<template>
    <div class="relative" v-on-clickaway="away">
        <div role="button" class="inline-block select-none" @click="open = !open">
            <slot name="link"></slot>
        </div>
        <div class="absolute pin-r w-full mt-2 z-50" v-show="open" @click="open = !open">
            <slot name="dropdown"></slot>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'DropdownButton',
  mixins: [ clickaway ],
  data() {
    return {
      open: false,
    };
  },
  methods: {
    away() {
      this.open = false;
    }
  }
};
</script>
