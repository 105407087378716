<template>
  <base-layout :menuItems="menuItems" origin="gd-dashboard">
    <template slot="content">
      <div class="w-full h-full flex flex-wrap" id="groovesellCont">
        <VuePerfectScrollbar
          class="w-full h-full p-4 sm:p-5 pt-3 relative"
          :settings="scrollbarSettings"
        >
          <div class="container mx-auto">
            <transition :name="routerTransition">
              <router-view></router-view>
            </transition>
          </div>
        </VuePerfectScrollbar>
      </div>
    </template>
    <template slot="header-bar">
      <header-bar
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        :routeTitle="routeTitle"
      ></header-bar>
    </template>

    <template slot="sidebar">
      <sidebar-menu></sidebar-menu>
    </template>
  </base-layout>
</template>

<script>
import store from '@/state';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BaseLayout from '@groovepages/gd-ui-kit/components/BaseLayout';
import { mapState } from 'vuex';

export default {
  components: {
    BaseLayout,
    VuePerfectScrollbar,
  },

  data() {
    return {
      widthModal: '680px',
      isExternal: true,
      showSlider: false,
      routerTransition: 'zoom-fade',
      routeTitle: this.$route.meta.pageTitle,
      isAdmin: null,
      scrollbarSettings: {
        maxScrollbarLength: 60,
      }
    };
  },

  computed: {
    ...mapState(['user']),

    menuItems() {
      return [];
    },
  },

  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },

  },

  created() {
    this.isAdmin = store.state.user.admin;
  },

  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    }
  }
};
</script>

<style lang="scss">
#groovesellCont {
  height: calc(100vh - 4rem) !important;
}
#swal2-title {
  line-height: 1.875rem;
  font-size: 1.875rem !important;
}

@layer utilities {
  .w-max {
    width: max-content;
  }
}
</style>
