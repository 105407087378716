<template>
    <div class="relative h-10 text-grey-600 border-b border-solid border-grey-200 rounded-lg bg-white flex-col" :class="{'border-pink-dark error-form-group': hasErrors}">
        <input :type="type" @keyup="keyup" ref="input" @keydown="keydown" :placeholder="placeholder" :value="value" :disabled="disabled" :readonly="readonly" @focus="inputFocussed" class="w-full h-full outline-none rounded-lg px-4" :min="min" :max="max">
        <div class="w-full error text-pink-dark mt-1 text-sm" v-if="hasErrors">
            {{ firstErrorMessage }}
        </div>
    </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default
{
  mixins: [singleErrorExtractorMixin],
  props: {
    value: {
    },
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
  },
  methods: {
    keyup() {
      this.$emit('input', this.$refs.input.value);
      this.$emit('keyup', this.$refs.input.value);
    },

    keydown(e) {
      this.$emit('keydown', e);
    },

    inputFocussed(e) {
      if (this.selectAll) {
        e.target.select();
      }
    },
  },
  name: 'NoHeadTextInput',
};
</script>

<style>
    .error-form-group
    {
        border-color: #ea3d5e !important;
    }
</style>
