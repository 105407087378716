<template>
    <div class="w-full flex flex-col items-left mr-4 mb-4 rounded border p-2" :class="{'border-pink' : this.activeOption === option.id, 'border-grey-200': this.activeOption !== option.id}" @click="updateActiveOption" @keydown.space="updateActiveOption">
        <label class="flex cursor-pointer" :class="{'cursor-not-allowed': option.coming_soon || option.is_disabled, 'cursor-pointer': !option.coming_soon && !option.is_disabled}">
            <span class="w-6 h-5 inline-block mr-1 mt-1 rounded-full border" :class="{'border-grey bg-pink text-pink activeOptionRadio' : this.activeOption === option.id, 'border-grey': this.activeOption !== option.id && !option.coming_soon && !option.is_disabled, 'border-grey-200': option.coming_soon || option.is_disabled}"></span>
            <div class="ml-2 flex flex-col w-full">
                <label class="font-semibold" :class="{'text-pink' : this.activeOption === option.id, 'text-grey-700': this.activeOption !== option.id && !option.coming_soon && !option.is_disabled, 'text-grey-300': option.coming_soon || option.is_disabled}">
                    {{ option.title }}
                    <div v-if="option.coming_soon" class="text-xs">({{ $t('frontend_coming_soon') }})</div>
                </label>
                <div class="text-grey-500">
                    {{ option.description }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
export default
{
    name: 'GdCustomRadio',
    model: {
      prop: 'activeOption',
      event: 'onUpdateOption',
    },
    props: ['option', 'activeOption'],
    methods: {
      updateActiveOption()
      {
          if(!this.option.coming_soon && !this.option.is_disabled)
          {
              this.$emit('onUpdateOption', this.option.id);
          }
      },
    },
};
</script>

<style>
    .activeOptionRadio
    {
        box-shadow: 0px 0px 0px 2px white inset;
    }
</style>
