<template>
  <div class="header-bar w-full flex flex-row">
    <portal-target name="headerBreadCrumb"></portal-target>
    <div class="flex flex-grow justify-between relative">
      <div class="flex text-sm pl-4 font-semibold">
        <span
          class="pr-4 flex items-center text-gray-700"
        >
          {{ routeTitle }}
        </span>
      </div>
    </div>
    <portal-target name="headerContent"></portal-target>
  </div>
</template>
<script>

export default {
  name: "HeaderBar",

  props: ["routeTitle"],

};
</script>
