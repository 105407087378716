<template>
<div class="h-screen">
  <side-bar
    :sections="sections"
    @open="setSideOpen"
    @close="setSideClosed"
    noExitButton="true"
    origin="Groove.cm"
    logo="GdLogo"
    class="h-full"
  >
    <template slot="Home"> </template>
    <template slot="Marketplace">
      <div class="text-sm">
        <ul class="cursor-pointer text-white list-reset p-4 blocks-container">
          <li
            class="mb-2 block-data relative focus:outline-none"
            @click="openUrl('/groovesell/marketplace', '_self')"
          >
            {{ $t('frontend_sites_side_bar_groove_affiliate_market') }}
          </li>
        </ul>
      </div>
    </template>
    <template :slot="$t('frontend_builder_our_services')">
      <div class="text-sm">
        <ul class="cursor-pointer text-white list-reset p-4 blocks-container">
            <li
              class="mb-2 block-data relative focus:outline-none"
              @click="openUrl('/static/groovepay', '_self')"
            >
              {{ $t('frontend_sites_side_bar_groovepay') }}
            </li>
            <li
              class="mb-2 block-data relative focus:outline-none"
              @click="openUrl('https://groovesolos.com/', '_blank')"
            >
              GrooveSolos
            </li>
        </ul>
      </div>
    </template>
    <template :slot="$t('frontend_builder_events_and_workshop')">
      <div class="text-sm">
        <ul class="cursor-pointer text-white list-reset p-4 blocks-container">
            <li
              class="mb-2 block-data relative focus:outline-none"
              @click="openUrl('https://groovekon.com/', '_blank')"
            >
              GrooveKon
            </li>
            <li
              class="mb-2 block-data relative focus:outline-none"
              @click="openUrl('https://marketerscruise.com/', '_blank')"
            >
              Marketers Cruise
            </li>
        </ul>
      </div>
    </template>

    <template slot="header">
      <div class="flex flex-col">
        <div>Groove.cm</div>
      </div>
    </template>
  </side-bar>
  <account-modal ref="accountModal" :open="editingUserDetails" @close="closeAccountModal" />
</div>
</template>

<script>
import SideBar from '@groovepages/gd-ui-kit/components/SideBarNew';
import HomeIcon from '@/assets/images/icons/Home.svg';
import MarketplaceIcon from '@/assets/images/icons/Marketplace.svg';
import AppStoreIcon from '@/assets/images/icons/AppStore.svg';
import ServicesIcon from '@/assets/images/icons/Services.svg';
import EventsIcon from '@/assets/images/icons/Events.svg';
import APIIcon from '@/assets/images/icons/API.svg';
import MobileAppIcon from '@/assets/images/icons/MobileAppIcon.svg';
import AccountModal from '@groovepages/gd-ui-kit/components/AccountModal';

export default {
  name: 'SidebarMenu',
  components: {
    SideBar,
    AccountModal
  },
  data() {
    return {
      editingUserDetails: false,
    };
  },
  computed: {
    sections() {
      const items = [
        { name: 'Home', icon: HomeIcon, doNotOpen: 1 },
        { name: 'Marketplace', icon: MarketplaceIcon },
        { name: this.$t('frontend_builder_our_services'), icon: ServicesIcon },
        { name: this.$t('frontend_builder_events_and_workshop'), icon: EventsIcon },
        { name: 'Groove.cm App', icon: MobileAppIcon, doNotOpen: 1 },
        { name: 'API/SDK', icon: APIIcon, doNotOpen: 1 }
      ];

      return items
    }
  },

  methods: {
    setSideOpen(side) {
      if (side === 'Home') {
        this.sendToDashboard();
      } else if (side === 'Groove.cm App') {
        this.openUrl('https://groovedigitalacademy.com/training/groove-cm-mobile-app/', '_blank');
      } else if (side == 'API/SDK') {
        this.editingUserDetails = true;
        this.$nextTick(() => {
          this.$refs.accountModal.$refs.tabsNew.openTab('Developer');
        });
      }
    },

    setSideClosed() {},

    sendToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },

    sendToDashboardApp() {
      this.$router.push({ name: 'dashboardApp' });
    },

    sendToMore() {
      this.$router.push({ name: 'more' });
    },

    openUrl(url, target) {
      window.open(url, target);
    },

    routeToGrooveMarketPlace() {
      window.location.replace('/static/groovemarketplace');
    },
    closeAccountModal() {
      this.editingUserDetails = false;
    },
  }
};
</script>
