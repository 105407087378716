<template>
  <div id="app">
    <loading ref="loading" />

    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import auth from '@groovepages/gd-lib/auth';
import Loading from './Loading';
import store from '@/state';
import Login from '../pages/auth/login.vue';

// Load layout components dynamically.
const requireContext = require.context('@/layouts', false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  components: {
    Loading,
  },

  data: () => ({
    layout: Login,
    defaultLayout: 'default',
  }),

  metaInfo() {
    const appName = 'Dashboard';

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`,
    };
  },

  created() {
    if (!auth.authenticatedUser()) {
    } else {
      store.commit('login', auth.authenticatedUser());
    }
  },
  mounted() {
    this.$loading = this.$refs.loading;
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    },
  },
};
</script>
