import axios from 'axios';
import Auth from '@groovepages/gd-lib/auth';
// import router from '@/router';
// import Swal from 'sweetalert2';
import store from '@/state';

/*
axios.interceptors.request.use(config => {
    config.baseURL = window.config.apiUrl;
    return config;
});
*/

// Request interceptor
axios.interceptors.request.use(request => {
  const { token } = Auth.authenticatedUser();

  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }

  /*
    const locale = store.getters['lang/locale']
    if (locale)
    {
        request.headers.common['Accept-Language'] = locale
    }
    */
  // request.headers['X-Socket-Id'] = Echo.socketId()
  return request;
});

// Response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status >= 500) {
      /*
        Swal.fire({
          type: 'error',
          title: 'Error',
          text:
            'Unknown Backend Error Occured. Please refresh and try again and if error persists, please contact support',
          reverseButtons: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel'
        });
      */
    }

    if (status === 401) {
      store.commit('logout');
      window.location.replace(`${window.config.grooveappsurl}/#/login`);
      window.location.reload();
      return Promise.reject(error);
    }
  }
);
