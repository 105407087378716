<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPageLoader"
      :color="loaderColor"
    ></loading>
    <div class="w-full flex flex-row mt-16 mb-12">
      <a :href="item.link" class="items-center flex flex-col mr-16 appBox" v-for="(item, index) in appList" :key="index">
        <div class="flex flex-row items-center justify-center bg-white border border-solid border-grey-200 imgBox mb-4">
          <img :src="require('../../assets/images/icons/'+item.icon)" :alt="item.name">
        </div>
        <h3 class="text-sm font-semibold text-color">{{item.name}}</h3>
      </a>
    </div>
    <h4 class="text-lg font-bold mb-8">{{ $t('frontend_coming_soon') }}</h4>
    <div class="w-full flex flex-row">
      <a :href="item.link" class="items-center flex flex-col mr-16 appBox" v-for="(item, index) in comingSoon" :key="index">
        <div class="flex flex-row items-center justify-center bg-white border border-solid border-grey-200 imgBox mb-4">
          <img :src="require('../../assets/images/icons/'+item.icon)" :alt="item.name">
        </div>
        <h3 class="text-sm font-semibold text-color">{{item.name}}</h3>
      </a>
    </div>
    <div class="flex w-full flex-col bg-white borderRadius border-solid border-grey-200 border mt-12">
      <div class="borderBottom p-4 relative">
        <h2 class="text-base text-gray-500 flex flex-row items-center font-bold">
          {{ $t('frontend_dashboard_recent_work') }}
          <span class="rightDots"><img src="../../assets/images/icons/more.png"></span>
        </h2>
      </div>
      <div class="w-full recentList">
        <div class="flex flex-row p-5 justify-between borderBottom items-center" v-for="(item, index) in recentWork" :key="index">
          <h4 class="text-pink text-sm w-2/5">{{item.topic}}</h4>
          <p class="text-sm text-color w-1/5">{{item.time}}</p>
          <p class="text-sm text-color w-1/5">{{item.category}}</p>
          <gd-button class="px-5 w-auto border-solid border-grey-200 border-b-2 text-grey-500">
            {{ $t('frontend_view') }}
          </gd-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InfoPanel from '@groovepages/gd-ui-kit/components/InfoPanel';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import themeConfig from '@/theme/themeConfig.js';

export default {
  middleware: 'auth',
  components: {
    Loading,
  },
  data() {
    return {
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
      appList: [
        {"name": 'GrooveSell', "icon": 'app-icon.png', "link": '/groovesell/'},
        {"name": 'GroovePages', "icon": 'app-icon.png', "link": '/groovepages/'},
        {"name": 'GrooveAffiliate', "icon": 'app-icon.png', "link": '/groovesell/affiliates-dashboard'},
        {"name": 'GrooveMail', "icon": 'app-icon.png', "link": '/groovemail/'},
        {"name": 'GrooveVideo', "icon": 'app-icon.png', "link": '/groovevideo/'},
        {"name": 'GrooveMember', "icon": 'app-icon.png', "link": '/groovemember/'}
      ],
      comingSoon: [
        {"name": 'GrooveBlog', "icon": 'app-icon.png', "link": '/static/grooveblog'},
        {"name": 'GrooveWebinar', "icon": 'app-icon.png', "link": '/static/groovewebinars'},
        {"name": 'GrooveDesk', "icon": 'app-icon.png', "link": '/static/groovedesk'},
        {"name": 'GrooveCalendar', "icon": 'app-icon.png', "link": '/static/groovecalendar'},
        {"name": 'GrooveSurvey', "icon": 'app-icon.png', "link": '/static/groovesurvey'},
      ],
      // recent work
      recentWork: [
        {'topic': 'Online course marketing site', 'time': '12 min ago', 'category': 'GroovePages'},
        {'topic': 'Online course marketing site', 'time': '12 min ago', 'category': 'GrooveMail'},
        {'topic': 'Online course marketing site', 'time': '12 min ago', 'category': 'GrooveSell'}
      ],
    };
  },
  metaInfo() {
    return { title: 'Dashboard App'};
  },
  computed: {
    
  },
  async created() {
    this.showLoader();

    this.hideLoader();
  },
  async mounted() {
    
  },
  methods: {
    showLoader() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },

    hideLoader() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

  }
};
</script>

<style>
.appBox .imgBox{
  width: 112px;
  height: 112px;
  border-radius: 18.7px;
  box-shadow: 0 4px 9px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  border: solid 1.2px #cccedc;
  background-color: #ffffff;
}
.appBox:last-child{
  margin-right: 0;
}
</style>
