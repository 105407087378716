<template>
  <div class="w-full h-full">
    <div class="w-full h-full flex flex-col">
      <div class="border-solid border-grey-200 border-b p-4 relative">
        <p class="text-base text-gray-500 flex flex-row items-center font-bold">
          {{ title }}
        </p>
      </div>
      <div
        class="w-full my-4 px-4 grooveSellChart flex flex-col justify-center"
      >
        <div class="w-full h-full flex flex-row items-center justify-between">
          <p v-if="sellNumber" class="text-base font-bold">
            {{ $t('frontend_gd_dashboard_total_sales') }}
          </p>
          <p v-else class="text-base font-bold">
            {{ $t('frontend_gd_dashboard_members') }}
          </p>
          <div class="flex rounded timePeriod">
            <span
              class="
                border-solid border-grey-200 border
                first
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 0 === activeItem }"
              @click="days(title, 0)"
            >
              {{ $t('frontend_gd_dashboard_today') }}
            </span>
            <span
              class="
                border-solid border-grey-200 border
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 1 === activeItem }"
              @click="days(title, 1)"
            >
              {{ $t('frontend_gd_dashboard_yesterday') }}
            </span>
            <span
              class="
                border-solid border-grey-200 border
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 2 === activeItem }"
              @click="days(title, 7)"
            >
              {{ $t('frontend_gd_dashboard_7days') }}
            </span>
            <span
              class="
                border-solid border-grey-200 border
                second
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 3 === activeItem }"
              @click="days(title, 14)"
            >
              {{ $t('frontend_gd_dashboard_14days') }}
            </span>
            <span
              class="
                border-solid border-grey-200 border
                third
                text-sm text-grey-500
                py-1
                px-2
                hover:text-pink
                cursor-pointer
                hover:border-pink
              "
              :class="{ active: 4 === activeItem }"
              @click="days(title, 30)"
            >
              {{ $t('frontend_gd_dashboard_30days') }}
            </span>
          </div>
        </div>
        <div class="w-full h-full" v-if="dataLoaded">
          <div class="w-full h-80 justify-center" v-if="hasUsed === 0">
            <div
              class="w-full flex flex-col justify-center items-center h-full"
            >
              <p class="text-xl font-bold mb-3">
                {{ $t('frontend_gd_dashboard_get_started_with') }} {{ title }}
              </p>
              <p class="text-sm w-1/2 text-center mb-4">
                {{ $t('frontend_gd_dashboard_get_no_data_to') }} {{ title }}
                {{ $t('frontend_gd_dashboard_get_today') }}.
              </p>
              <gd-button primary class="h-10 w-32" @click="linkRedirect(title)">
                {{ $t('frontend_gd_dashboard_get_started') }}
              </gd-button>
            </div>
          </div>
          <div
            class="w-full h-80 justify-center items-center"
            v-else-if="hasData === 0"
          >
            <div
              class="w-full flex flex-col justify-center items-center h-full"
            >
              <img
                src="../../assets/images/icons/dashboard/emptyinsights.png"
              />
              <p class="text-sm w-1/2 text-center mb-4 mt-4">
                {{ $t('frontend_gd_dashboard_nodata_to_show') }}
              </p>
            </div>
          </div>
          <div v-else>
            <div
              class="
                w-full
                flex flex-row
                items-center
                justify-between
                mt-2
                mb-2
              "
              v-if="sellNumber"
            >
              <div class="flex flex-col">
                <p class="text-3xl text-color font-bold">
                  {{
                    sellNumber.sales_count
                      ? this.numberSystem(sellNumber.sales_count, 0)
                      : 0
                  }}+{{
                    sellNumber.rebills_count
                      ? this.numberSystem(sellNumber.rebills_count, 0)
                      : 0
                  }}
                </p>
                <span class="text-sm text-gray-100">
                  {{ $t('frontend_gd_dashboard_sales_rebills') }}
                </span>
              </div>
              <div class="flex flex-col">
                <p class="text-3xl text-color font-bold">
                  {{
                    sellNumber.refunded_count
                      ? this.numberSystem(sellNumber.refunded_count, 0)
                      : 0
                  }}
                </p>
                <span class="text-sm text-gray-100">
                  {{ $t('frontend_gd_dashboard_refunds') }}
                </span>
              </div>
              <div class="flex flex-col">
                <p class="text-3xl text-color font-bold">
                  ${{
                    sellNumber.revenue
                      ? this.numberSystem(sellNumber.revenue, 2)
                      : 0
                  }}
                </p>
                <span class="text-sm text-gray-100">
                  {{ $t('frontend_gd_dashboard_revenue') }}
                </span>
              </div>
              <div class="flex flex-col">
                <p class="text-3xl text-color font-bold">
                  ${{
                    sellNumber.profit
                      ? this.numberSystem(sellNumber.profit, 2)
                      : 0
                  }}
                </p>
                <span class="text-sm text-gray-100">
                  {{ $t('frontend_gd_dashboard_profit') }}
                </span>
              </div>
            </div>
            <div
              class="
                w-full
                flex flex-row
                items-center
                justify-between
                mt-2
                mb-2
              "
              v-if="memberNumber"
            >
              <div class="flex flex-col">
                <p
                  class="text-3xl text-color font-bold"
                  v-if="memberNumber.total_members_count"
                >
                  {{
                    memberNumber.total_members_count
                      ? this.numberSystem(memberNumber.total_members_count, 0)
                      : 0
                  }}
                </p>
                <p class="text-3xl text-color font-bold" v-else>0</p>
                <span class="text-sm text-gray-100">Total Users</span>
              </div>
              <div class="flex flex-col">
                <p
                  class="text-3xl text-color font-bold"
                  v-if="memberNumber.active_members_count"
                >
                  {{
                    memberNumber.total_members_count
                      ? this.numberSystem(memberNumber.active_members_count, 0)
                      : 0
                  }}
                </p>
                <p class="text-3xl text-color font-bold" v-else>0</p>
                <span class="text-sm text-gray-100">New Users</span>
              </div>
              <div class="flex flex-col">
                <p
                  class="text-3xl text-color font-bold"
                  v-if="memberNumber.inactive_members_count"
                >
                  {{
                    memberNumber.total_members_count
                      ? this.numberSystem(
                          memberNumber.inactive_members_count,
                          0
                        )
                      : 0
                  }}
                </p>
                <p class="text-3xl text-color font-bold" v-else>0</p>
                <span class="text-sm text-gray-100">Cancellations</span>
              </div>
            </div>
            <vue-apex-charts
              ref="realtimeChart"
              type="bar"
              height="300"
              :options="chartsOption"
              :series="chartSeries"
              class="w-full"
            ></vue-apex-charts>
          </div>
        </div>
        <div v-else>
          <chartContentLoader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import themeConfig from '@/theme/themeConfig.js';
import VueApexCharts from 'vue-apexcharts';
import chartContentLoader from './chartcontentloader';

export default {
  middleware: 'auth',
  components: {
    Loading,
    apexchart: VueApexCharts,
    chartContentLoader,
  },
  props: {
    title: {
      required: true,
    },
    chartsOption: {
      required: true,
    },
    chartSeries: {
      required: true,
    },
    sellNumber: {
      type: Object,
    },
    memberNumber: {
      type: Array,
    },
    activeItem: {
      type: Number,
    },
    errResponse: {
      default: true,
    },
    hasUsed: {
      default: 0,
    },
    hasData: {
      default: 0,
    },
    dataLoaded: {
      default: 0,
    },
  },
  data() {
    return {
      fullPageLoader: true,
      isLoading: false,
      loaderColor: themeConfig.appPrimaryColor,
    };
  },
  async created() {
    await this.updateSeriesLine();
  },
  async mounted() {},
  methods: {
    showLoader() {
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },

    hideLoader() {
      if (this.isLoading) {
        this.isLoading = false;
      }
    },

    // for chart data
    updateSeriesLine() {
      if (this.hasUsed && this.hasData) {
        this.$nextTick(() => {
          this.$refs.realtimeChart.updateSeries(
            [
              {
                data: this.chartSeries[0].data,
              },
            ],
            false,
            true
          );
        });
      }
    },

    days(name, number) {
      if (name === 'GrooveMember') {
        this.$emit('grooveMember', number);
      } else if (name === 'GrooveSell') {
        this.$emit('grooveSell', number);
      }
    },

    numberSystem(num, decimals) {
      if (decimals === 0) {
        return parseInt(num).toLocaleString();
      }

      return parseInt(num)
        .toFixed(decimals)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    sellLink() {
      this.$emit('redirectUrl', 'GrooveSell');
    },

    memberLink() {
      this.$emit('redirectUrl', 'Create New Membership');
    },
  },
};
</script>

<style>
.columnWidth {
  width: 49%;
}
.rightDots {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, 0);
}
.text-color {
  color: #191d3a;
}
/* chartCss */

.grooveSellChart .apexcharts-tooltip-marker,
.grooveSellChart .apexcharts-tooltip-title,
.grooveSellChart .apexcharts-tooltip.light {
  background: #fd6481 !important;
}
.grooveSellChart .apexcharts-menu-item,
.grooveSellChart .apexcharts-zoom-icon {
  color: #fd6481 !important;
}
.grooveSellChart .apexcharts-bar-area {
  fill: #fd6481 !important;
}
.grooveSellChart .apexcharts-line {
  stroke: #fd6481 !important;
}
.active {
  color: #fd6481;
  border-color: #fd6481;
}
.chartBox.errBox {
  height: calc(100vh - 275px);
}

.sellChart.errBox {
  height: calc(100vh - 285px);
}
</style>
