<template>
    <div class="w-full h-40 p-4 border border-solid border-grey-200 rounded-lg flex flex-col bg-white">
        <div class="flex h-16 text-sm text-grey-600">
            <slot name="header"></slot>
        </div>
        <div class="flex flex-grow text-grey-800 text-5xl">
            <slot name="content"></slot>
        </div>
        <div class="flex h-10">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dashboard-card'
};
</script>
