import { importModule } from '@uupaa/dynamic-import-polyfill';
import configValues from './config';

const production = () =>
  new Promise(resolve => {
    importModule('/config/config.js').then(config => {
      resolve(config.default);
    });
  });

const local = () =>
  new Promise(resolve => {
    resolve(configValues);
  });

const config = process.env.NODE_ENV === 'development' ? local() : production();

export default config;
