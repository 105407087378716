<template>
	<transition :name="router_transition" mode="out-in">
		<slot>
			<router-view />
		</slot>
	</transition>
</template>

<script>
export default
{
  name: 'Child',
  props: ['router_transition'],
};
</script>
