<template>
  <nav class="tabs" :class="wrapperClass">
    <button class="tabs__item" type="button" v-for="tab in tabs" :ref="tab.value" :key="tab.value"
      :class="[
        {'tabs__item_active' : tab.value === currentTab },
        tab.value === currentTab && tabActiveClass ? tabActiveClass: '',
        tabClass,
        disableNotCompleted ? tabCompletedOrNotClass(tab.value) : '',
        errorTabs.includes(tab.value) ? 'tabHeadingWithError' : ''
      ]"
      :disabled="tab.disabled || false"
      @click="handleClick(tab.value)"
      v-html="tabTitle(tab)"
    />

    <div
      class="tabs__active-line"
      :class="lineClass"
      :style="{ width: `${activeLineWidth}px`, transform: `translateX(${activeLineOffset}px)` }"
    />
  </nav>
</template>

<script>
export default {
  name: 'GdTabs',
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    errorTabs: {
      type: Array,
      default: () => []
    },
    highlightTabs: {
        type: Array,
        default: () => []
    },
    highlightText: '',
    updated: {
      type: [Boolean, String, Array],
      default: undefined,
    },
    wrapperClass: {
      type: String,
      required: false,
    },
    disableNotCompleted: {
        type: Boolean,
        default: false,
    },
    tabClass: {
      type: String,
      required: false,
    },
    tabActiveClass: {
      type: String,
      required: false,
    },
    lineClass: {
      type: String,
      required: false,
    },
  },
  watch: {
    currentTab(newCurrentTab) {
      if (this.newTab === newCurrentTab) return;
      this.moveActiveLine(newCurrentTab);
    },
    updated() {
      this.moveActiveLine(this.currentTab);
    },
  },
  data: () => ({
    activeLineWidth: 0,
    activeLineOffset: 0,
    newTab: '',
  }),
  methods: {
    handleClick(value) {
      this.$emit('onClick', value);
      this.moveActiveLine(value);

      this.newTab = value;
    },
    moveActiveLine(newValue) {
      if (!this.currentTab) return;

      if (!this.$refs || !this.$refs[newValue] || !this.$refs[newValue][0]) return;
      const element = this.$refs[newValue][0];

      this.activeLineWidth = element.clientWidth;
      this.activeLineOffset = element.offsetLeft;
    },
    tabCompletedOrNotClass(value)
    {
        let self = this;
        let currentSelectedTabIndex = this.tabs.findIndex(function(obj){return obj.value === self.currentTab});
        let currentRenderingTabIndex = this.tabs.findIndex(function(obj){return obj.value === value});

        if(currentRenderingTabIndex > currentSelectedTabIndex)
        {
            return 'text-grey-300';
        }
        else
        {
            return '';
        }
    },

    tabTitle(tab)
    {
        if(this.errorTabs.includes(tab.value))
        {
            return tab.title + '<span class="ml-2 text-red"><i class="fas fa-exclamation-triangle"></i></span>';
        }
        else if(this.highlightTabs.includes(tab.value))
        {
            return tab.title + '<span class="ml-2 bg-orange text-white text-xs px-2 rounded">' + this.highlightText + '</span>';
        }
        else
        {
            return tab.title;
        }
    }
  },
  mounted() {
    this.moveActiveLine(this.currentTab);
  },
  computed: {

  }
};
</script>

<style>
    .tabHeadingWithError
    {
        color: #FD9602;
    }
</style>
