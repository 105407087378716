import Vue from 'vue';
import GdButton from '@groovepages/gd-ui-kit/components/Button';
import GdCheckbox from '@groovepages/gd-ui-kit/components/Checkbox';
import ConfirmModal from '@groovepages/gd-ui-kit/components/ConfirmModal';
import GdDropdown from '@groovepages/gd-ui-kit/components/Dropdown';
import ExpansionPanel from '@groovepages/gd-ui-kit/components/ExpansionPanel';
import InfoPanel from '@groovepages/gd-ui-kit/components/InfoPanel';
import GdModal from '@groovepages/gd-ui-kit/components/Modal';
import GdSlider from '@groovepages/gd-ui-kit/components/Slider';
import DropMenu from '@groovepages/gd-ui-kit/components/DropMenu';
import GdToggle from '@groovepages/gd-ui-kit/components/Toggle';
import TextArea from './TextArea';
import TextInput from './TextInput';
import NoHeadTextInput from './NoHeadTextInput';
import PasswordInput from './PasswordInput';
import FeatherIcon from './FeatherIcon';
import Child from './Child';
import GdCard from './GdCard';
import SidebarMenu from './SidebarMenu';
import DashboardCard from './DashboardCard';
import CheckoutRadio from './CheckoutRadio';
import CheckoutPagePaymentOption from './CheckoutPagePaymentOption';
import FontAwesomeRadio from './FontAwesomeRadio';
import FontAwesomeRadioWithCount from './FontAwesomeRadioWithCount';
import GdDropdownButton from './GdDropdownButton';
import GdDropdownContent from './GdDropdownContent';
import GdDropdownItem from './GdDropdownItem';
import HeaderBar from './HeaderBar';
import DropdownButton from './DropdownButton';
import GdCustomRadio from './GdCustomRadio';
import CheckoutTemplateRadio from './CheckoutTemplateRadio';
import GdTabs from './GdTabs';

// Components that are registered globaly.
[
  Child,
  GdButton,
  GdCheckbox,
  GdDropdownButton,
  GdDropdownContent,
  GdDropdownItem,
  ConfirmModal,
  FeatherIcon,
  GdDropdown,
  ExpansionPanel,
  InfoPanel,
  GdModal,
  GdSlider,
  HeaderBar,
  TextArea,
  TextInput,
  NoHeadTextInput,
  PasswordInput,
  GdToggle,
  GdCard,
  SidebarMenu,
  DashboardCard,
  CheckoutRadio,
  CheckoutPagePaymentOption,
  DropMenu,
  DropdownButton,
  GdCustomRadio,
  GdTabs,
  CheckoutTemplateRadio,
  FontAwesomeRadio,
  FontAwesomeRadioWithCount
].forEach((Component) => {
  Vue.component(Component.name, Component);
});
