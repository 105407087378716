<template>
  <div>
    <ContentLoader viewBox="0 0 180 400" height="200" width="180">
      <rect x="10" y="78" rx="0" ry="0" width="15" height="50"></rect>
      <rect x="30" y="53" rx="0" ry="0" width="15" height="75"></rect>
      <rect x="50" y="68" rx="0" ry="0" width="15" height="60"></rect>
      <rect x="70" y="48" rx="0" ry="0" width="15" height="80"></rect>
      <rect x="90" y="53" rx="0" ry="0" width="15" height="75"></rect>
      <rect x="110" y="58" rx="0" ry="0" width="15" height="70"></rect>
      <rect x="130" y="68" rx="0" ry="0" width="15" height="60"></rect>
      <rect x="150" y="48" rx="0" ry="0" width="15" height="80"></rect>
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  middleware: 'auth',
  components: {
    ContentLoader
  },
  props: {},
  data() {
    return {};
  },
  async created() {},
  async mounted() {},
  methods: {}
};
</script>
